<template>
  <div class="home">
    <div class="home_container">
      <div class="home_background"></div>
      <div class="home_content">
        <img src="../assets/image/homeLogo.png" alt="" />
        <p>
          KOLBOX-social is a subsidiary company belonging to HAIMA Media. HAIMA
          Media is founded in 2020, with a wealth of experience in influencer
          marketing, we are helping lots of brands to do influencer content
          strategy, creative campaigns, project management, advertising
          optimization, and data reporting. Through every collaboration pitched,
          every contract secured, and every partnership built, we strive to
          empower both our influencers and the brands we work with to utilize
          this ever-growing social space for the better.
        </p>
      </div>
    </div>
    <ul class="app_link">
      <li>
        <a href="https://www.tiktok.com/@haimamedia_official" target="_blank"></a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCdYjLQIFkJcNDbtO-yY6ftA" target="_blank"></a>
      </li>
      <li>
        <router-link to="/contactUs"></router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    setup() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };

</script>
<style lang="less" scoped>
  .home {
    // min-height: calc(100vh);
    // height: 16rem;
    background: url(../assets/image/homeBack.png) no-repeat center;
    background-size: cover;
    padding-top: 1rem;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 1.5rem;

    .home_container {
      margin: 1.3rem auto 0;
      position: relative;
      width: 6.2rem;
      height: 12.27rem;
      overflow: hidden;
      border-radius: 0.2rem;

      .home_background {
        position: absolute;
        background: #000000;
        width: 100%;
        height: 100%;
        opacity: 0.8;
      }

      .home_content {
        position: relative;
        z-index: 2;

        img {
          display: block;
          margin: 0 auto;
        }

        img:nth-child(1) {
          width: 3.6rem;
          margin: 1.12rem auto 0.34rem;
        }

        img:nth-child(2) {
          width: 1.8rem;
        }

        img:nth-child(3) {
          width: 3rem;
        }

        p {
          padding: 0.5rem 0.78rem;
          font-size: 0.29rem;
          font-family: Arial;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.45rem;
          text-align: center;
        }
      }
    }

    .app_link {
      display: flex;
      position: absolute;
      bottom: .5rem;
      left: 50%;
      transform: translateX(-50%);

      li {
        a {
          display: block;
          width: 0.65rem;
          height: 0.65rem;
          background: url(../assets/image/homeLink.png) no-repeat;
          background-size: 455%;
        }
      }

      li:nth-child(2) {
        margin-left: 0.49rem;

        a {
          background-position: -2.3rem 0;
        }
      }

      li:nth-child(3) {
        margin-left: 0.49rem;

        a {
          background-position: -1.15rem 0;
        }
      }
    }
  }

</style>
