<template>
  <div id="nav">
    <ul class="nav_list">
      <li>
        <router-link
          :to="'/'"
          :class="{ current_router: state.currentRoute === '/' }"
          >Home
        </router-link>
      </li>
      <li>
        <router-link
          :to="'/weDo'"
          :class="{ current_router: state.currentRoute === '/weDo' }"
          >What We Do</router-link
        >
      </li>
      <li>
        <router-link
          :to="'/brands'"
          :class="{ current_router: state.currentRoute === '/brands' }"
          >Brands</router-link
        >
      </li>
      <li>
        <router-link
          :to="'/talent'"
          :class="{ current_router: state.currentRoute === '/talent' }"
          >Influencer</router-link
        >
      </li>
    </ul>
  </div>
  <div class="container" @click="state.isShowNav = false">
    <router-view />
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { computed, reactive, toRaw } from "vue";
export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      currentRoute: computed(() => toRaw(route).path.value),
      isShowNav: false
    });
    return { state };
  }
};
</script>
<style lang="less">
#app {
  max-width: 750px;
  margin: 0 auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nav {
  top: 0;
  position: fixed;
  width: 7.5rem;
  line-height: 1rem;
  background: #000000;
  font-size: 0.33rem;
  font-family: Arial;
  font-weight: 400;
  color: #ffffff;
  z-index: 9;
  text-align: center;
  .nav_list {
    height: 1.2rem;
    overflow: hidden;
    transition: all 0.5s;
    display: flex;
    li {
      width: 25%;
      line-height: 1.2rem;
      a {
        color: #ffffff;
        font-size: 0.28rem;
        font-family: Arial;
        font-weight: 400;
      }
      .current_router {
        color: #f7323f;
        text-decoration: underline;
        font-size: 0.32rem;
      }
    }
  }
  .is_show_nav {
    height: 5rem;
    transition: all 0.5s;
  }
}
</style>
